import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 50,
  },
  path: {
    fill: '#00a760',
    fillOpacity: 1,
    fillRule: 'nonzero',
    stroke: 'none',
  },
});
const LogoFull = () => {
  const classes = useStyles();

  return (
    <svg
      className={classes.svg}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1133.8583 476.92911"
    >
      <g transform="translate(131.22659,-2.9772145)">
        <g transform="matrix(1.6345922,0,0,1.6345922,118.70845,-224.21545)">
          <path
            className={classes.path}
            d="M 388.98149,213.98204 L 377.22044,213.98204 C 357.84995,187.55263 326.56359,170.38986 291.28041,170.38986 C 253.74939,170.38986 220.7542,189.81768 201.79659,219.16012 C 182.83898,189.81768 149.84379,170.38986 112.31277,170.38986 C 77.029593,170.38986 45.771899,187.5985 26.401418,214.03365 L 14.651828,214.03365 C 35.306793,181.95021 71.318226,160.64727 112.31277,160.64727 C 148.3242,160.64727 180.50512,177.04165 201.79659,202.77721 C 223.08806,177.04165 255.26898,160.64727 291.28041,160.64727 C 332.29216,160.64727 368.33226,181.87567 388.98149,213.98204"
          />
          <path
            className={classes.path}
            d="M 389.22692,355.36994 C 368.61783,387.67702 332.44584,409.10611 291.285,409.10611 C 255.26783,409.10611 223.08692,392.706 201.79544,366.97044 C 180.50397,392.706 148.32305,409.10611 112.31162,409.10611 C 71.156518,409.10611 35.007462,387.69422 14.392637,355.39861 L 14.392637,355.38715 L 26.113556,355.38715 C 45.461099,382.03446 76.862151,399.35779 112.31162,399.35779 C 149.84264,399.35779 182.83783,379.9357 201.79544,350.58753 C 220.75305,379.9357 253.74824,399.35779 291.285,399.35779 C 326.73447,399.35779 358.14126,382.02299 377.4888,355.37568 L 389.22692,355.36994"
          />
          <path
            className={classes.path}
            style={{ fillRule: 'evenodd' }}
            d="M -23.677411,287.5417 C -23.677411,269.55892 -12.214536,254.98232 2.321926,254.98232 C 16.852654,254.98232 28.321263,269.55892 28.321263,287.5417 C 28.321263,305.52448 16.852654,320.10108 2.321926,320.10108 C -12.214536,320.10108 -23.677411,305.52448 -23.677411,287.5417 z M -43.713071,287.5417 C -43.713071,261.78894 -23.103981,240.91607 2.321926,240.91607 C 27.747832,240.91607 48.356923,261.78894 48.356923,287.5417 C 48.356923,313.29446 27.747832,334.16733 2.321926,334.16733 C -23.103981,334.16733 -43.713071,313.29446 -43.713071,287.5417"
          />
          <path
            className={classes.path}
            d="M 440.22897,331.68153 L 459.15218,331.68153 L 459.15218,243.71156 L 440.22897,243.71156 L 440.22897,331.68153 z"
          />
          <path
            className={classes.path}
            d="M -87.720417,308.21845 L -109.60253,243.71326 L -131.22659,243.71326 L -98.323147,331.67749 L -79.738267,331.67749 L -47.38532,243.71326 L -65.184597,243.71326 L -87.645877,308.40769 L -87.720417,308.21845"
          />
          <path
            className={classes.path}
            d="M 272.0292,243.71211 L 272.0292,331.68208 L 335.86348,331.68208 L 335.86348,317.41513 L 290.95241,317.41513 L 290.95241,293.41133 L 329.17155,293.41133 L 329.17155,279.15012 L 290.95241,279.15012 L 290.95241,257.97906 L 333.87368,257.97906 L 333.87368,243.71211 L 272.0292,243.71211"
          />
          <path
            className={classes.path}
            d="M 200.67152,275.27318 L 214.87539,331.68153 L 233.65524,331.68153 L 258.32995,243.71156 L 241.38508,243.71156 L 225.52973,304.71883 L 211.71579,243.71156 L 193.89357,243.71156 L 178.88116,304.99981 L 164.85505,243.71156 L 143.78722,243.71156 L 168.18095,331.68153 L 186.80597,331.68153 L 200.67152,275.27318"
            id="path5639"
          />
          <path
            className={classes.path}
            d="M 90.444425,289.23736 L 114.35647,331.67694 L 138.10796,331.67694 L 115.43452,298.17714 C 121.381,295.96943 134.34626,287.68336 134.34626,272.72829 C 134.34626,253.94844 119.76966,243.71271 104.43039,243.71271 L 63.716831,243.71271 L 63.716831,331.67694 L 82.640037,331.67694 L 82.640037,257.18833 L 98.77637,257.18833 C 107.29181,257.18833 114.93564,262.7334 114.93564,273.20997 C 114.93564,283.73242 105.73208,289.44379 95.530753,289.44379 C 93.569621,289.44379 91.895204,289.50113 90.444425,289.23736"
          />
          <path
            className={classes.path}
            d="M 379.72403,289.23736 L 403.63608,331.67694 L 427.38757,331.67694 L 404.7084,298.17714 C 410.66061,295.96943 423.62013,287.68336 423.62013,272.72829 C 423.62013,253.94844 409.04927,243.71271 393.71,243.71271 L 352.9907,243.71271 L 352.9907,331.67694 L 371.91964,331.67694 L 371.91964,257.18833 L 388.05598,257.18833 C 396.57142,257.18833 404.21525,262.7334 404.21525,273.20997 C 404.21525,283.73242 395.01169,289.44379 384.81036,289.44379 C 382.85496,289.44379 381.17481,289.50113 379.72403,289.23736"
          />
          <path
            className={classes.path}
            d="M 461.60302,283.89468 L 493.84128,331.67864 L 519.08369,331.67864 L 482.30386,282.87397 L 514.95499,243.71441 L 492.27008,243.71441 L 461.60302,283.89468"
          />
        </g>
      </g>
    </svg>
  );
};

export default LogoFull;
