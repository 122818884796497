import { Entity } from '@backstage/catalog-model';
import {
  DefaultEntityFilters,
  EntityFilter,
} from '@backstage/plugin-catalog-react';
import { CRITICALITY_LABEL, PERSONAL_DATA_LABEL } from './constants';

const CRITICALITY_PATH = `metadata.labels.${CRITICALITY_LABEL}`;
const PERSONAL_DATA_PATH = `metadata.labels.${PERSONAL_DATA_LABEL}`;

export class EntityCriticalityFilter implements EntityFilter {
  constructor(readonly values: string[]) {}

  getCatalogFilters() {
    const filters: Record<string, string | string[]> = {};
    filters[CRITICALITY_PATH] = this.values;
    return filters;
  }

  filterEntity(entity: Entity): boolean {
    const criticality = entity.metadata.labels?.[CRITICALITY_LABEL];
    return criticality !== undefined && this.values.includes(criticality);
  }

  toQueryValue(): string[] {
    return this.values;
  }
}

export class EntityPersonalDataFilter implements EntityFilter {
  constructor(readonly values: string[]) {}

  getCatalogFilters() {
    const filters: Record<string, string | string[]> = {};
    filters[PERSONAL_DATA_PATH] = this.values;
    return filters;
  }

  filterEntity(entity: Entity): boolean {
    const personalData = entity.metadata.labels?.[PERSONAL_DATA_LABEL];
    return personalData !== undefined && this.values.includes(personalData);
  }

  toQueryValue(): string[] {
    return this.values;
  }
}

export type CustomFilters = DefaultEntityFilters & {
  criticalities?: EntityCriticalityFilter | undefined;
  personalData?: EntityPersonalDataFilter | undefined;
};
