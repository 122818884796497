import React, { useState } from 'react';
import useAsync from 'react-use/lib/useAsync';
import { makeStyles } from '@material-ui/core';
import {
  CATALOG_FILTER_EXISTS,
  catalogApiRef,
  CatalogApi,
  useEntityOwnership,
  EntityListProvider,
  CatalogFilterLayout,
  UserListPicker,
  EntityOwnerPicker,
  EntityTagPicker,
} from '@backstage/plugin-catalog-react';
import { Entity } from '@backstage/catalog-model';
import {
  DocsTable,
  DocsCardGrid,
  TechDocsPageWrapper,
  TechDocsPicker,
  EntityListDocsTable,
  TechDocsCustomHomeProps,
  PanelConfig,
} from '@backstage/plugin-techdocs';
import {
  CodeSnippet,
  Content,
  HeaderTabs,
  Progress,
  WarningPanel,
  SupportButton,
  ContentHeader,
} from '@backstage/core-components';
import { useApi } from '@backstage/core-plugin-api';

const panels = {
  DocsTable: DocsTable,
  DocsCardGrid: DocsCardGrid,
};

const CustomPanel = ({
  config,
  entities,
  index,
}: {
  config: PanelConfig;
  entities: Entity[];
  index: number;
}) => {
  const useStyles = makeStyles({
    panelContainer: {
      marginBottom: '2rem',
      ...(config.panelCSS ? config.panelCSS : {}),
    },
  });
  const classes = useStyles();
  const { loading: loadingOwnership, isOwnedEntity } = useEntityOwnership();

  const Panel = panels[config.panelType ?? 'DocsCardGrid'];

  const shownEntities = entities.filter(entity => {
    if (config.filterPredicate === 'ownedByUser') {
      if (loadingOwnership) {
        return false;
      }
      return isOwnedEntity(entity);
    }

    return (
      typeof config.filterPredicate === 'function' &&
      config.filterPredicate(entity)
    );
  });

  return (
    <>
      <ContentHeader title={config.title} description={config.description}>
        {index === 0 ? (
          <SupportButton>
            Discover documentation in your ecosystem.
          </SupportButton>
        ) : null}
      </ContentHeader>
      <div className={classes.panelContainer}>
        {config.filterPredicate === 'all' ? (
          <EntityListProvider>
            <CatalogFilterLayout>
              <CatalogFilterLayout.Filters>
                <TechDocsPicker />
                <UserListPicker initialFilter="owned" />
                <EntityOwnerPicker />
                <EntityTagPicker />
              </CatalogFilterLayout.Filters>
              <CatalogFilterLayout.Content>
                <EntityListDocsTable />
              </CatalogFilterLayout.Content>
            </CatalogFilterLayout>
          </EntityListProvider>
        ) : (
          <Panel data-testid="techdocs-custom-panel" entities={shownEntities} />
        )}
      </div>
    </>
  );
};

export const CustomTechDocsHome = (props: TechDocsCustomHomeProps) => {
  const { tabsConfig } = props;
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const catalogApi: CatalogApi = useApi(catalogApiRef);

  const {
    value: entities,
    loading,
    error,
  } = useAsync(async () => {
    const response = await catalogApi.getEntities({
      filter: {
        'metadata.annotations.backstage.io/techdocs-ref': CATALOG_FILTER_EXISTS,
      },
      fields: [
        'apiVersion',
        'kind',
        'metadata',
        'relations',
        'spec.owner',
        'spec.type',
      ],
    });
    return response.items.filter((entity: Entity) => {
      return !!entity.metadata.annotations?.['backstage.io/techdocs-ref'];
    });
  });

  const currentTabConfig = tabsConfig[selectedTab];

  if (loading) {
    return (
      <TechDocsPageWrapper>
        <Content>
          <Progress />
        </Content>
      </TechDocsPageWrapper>
    );
  }

  if (error) {
    return (
      <TechDocsPageWrapper>
        <Content>
          <WarningPanel
            severity="error"
            title="Could not load available documentation."
          >
            <CodeSnippet language="text" text={error.toString()} />
          </WarningPanel>
        </Content>
      </TechDocsPageWrapper>
    );
  }

  return (
    <TechDocsPageWrapper>
      <HeaderTabs
        selectedIndex={selectedTab}
        onChange={index => setSelectedTab(index)}
        tabs={tabsConfig.map(({ label }, index) => ({
          id: index.toString(),
          label,
        }))}
      />
      <Content data-testid="techdocs-content">
        {currentTabConfig.panels.map((config, index) => (
          <CustomPanel
            key={index}
            config={config}
            entities={!!entities ? entities : []}
            index={index}
          />
        ))}
      </Content>
    </TechDocsPageWrapper>
  );
};
