import { createTranslationRef } from '@backstage/core-plugin-api/alpha';

/** @alpha */
export const catalogTranslationRef = createTranslationRef({
  id: 'vorwerk',
  messages: {
    aboutCard: {
      title: 'About',
      refreshButtonTitle: 'Schedule entity refresh',
      editButtonTitle: 'Edit Metadata',
      createSimilarButtonTitle: 'Create something similar',
      refreshScheduledMessage: 'Refresh scheduled',
      launchTemplate: 'Launch Template',
      viewTechdocs: 'View TechDocs',
      viewSource: 'View Source',
      descriptionField: {
        label: 'Description',
        value: 'No description',
      },
      ownerField: {
        label: 'Owner',
        value: 'No Owner',
      },
      domainField: {
        label: 'Domain',
        value: 'No Domain',
      },
      systemField: {
        label: 'System',
        value: 'No System',
      },
      parentComponentField: {
        label: 'Parent Component',
        value: 'No Parent Component',
      },
      typeField: {
        label: 'Type',
      },
      lifecycleField: {
        label: 'Lifecycle',
      },
      tagsField: {
        label: 'Tags',
        value: 'No Tags',
      },
      targetsField: {
        label: 'Targets',
      },
      criticalityField: {
        label: 'Criticality',
      },
      exposureField: {
        label: 'Exposure',
      },
      personalDataField: {
        label: 'Personal data',
      },
      productsField: {
        label: 'Products',
        value: 'No Products',
      },
      linksField: {
        label: 'Links',
      },
    },
    entityCriticalityPicker: {
      title: 'Criticality',
    },
  },
});
