import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import type { BuiltInNode } from '@xyflow/react';
import { Controls, MiniMap, ReactFlow, useNodesState } from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import React, { useEffect } from 'react';

export default function CulinaryContent() {
  const catalogApi = useApi(catalogApiRef);

  const initialNodes = new Array<any>();

  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);

  const parentDomainName = 'culinary';
  const allowedUserDescriptions = [
    'people manager',
    'product manager',
    'domain product owner',
  ];
  const padding = 50;
  const basicNodeWidth = 150;

  useEffect(() => {
    const nodes = new Array<BuiltInNode>();
    const getData = async () => {
      const culinaryDomains = await catalogApi.getEntities({
        fields: ['metadata.name', 'metadata.title', 'spec.owner'],
        filter: [{ kind: 'Domain', 'spec.subdomainOf': parentDomainName }],
        order: { field: 'metadata.title', order: 'asc' },
      });

      if (culinaryDomains && culinaryDomains.items) {
        for (let i = 0; i < culinaryDomains.items.length; i++) {
          const domain = culinaryDomains.items[i];
          const domainNodeName = `domain-${domain.metadata.name}`;
          const domainNode: BuiltInNode = {
            id: domainNodeName,
            data: { label: domain.metadata.title || domain.metadata.name },
            position: { x: 0, y: 0 },
          };
          nodes.push(domainNode);
          let lastYIndex = padding;

          const users = await catalogApi.getEntities({
            fields: ['metadata.name', 'metadata.title', 'metadata.description'],
            filter: {
              kind: 'User',
              'relations.memberof': `group:default/${domain.spec!.owner}`,
            },
            order: { field: 'metadata.title', order: 'asc' },
          });

          const dltUsers = users.items
            .filter(user =>
              allowedUserDescriptions.some(value =>
                user.metadata.description?.toLocaleLowerCase().includes(value),
              ),
            )
            .map(
              user => `${user.metadata.description}: ${user.metadata.title} `,
            )
            .join(' ');

          const dltNodeName = `dlt-${domain.metadata.name}`;
          const dltNode: BuiltInNode = {
            id: dltNodeName,
            data: { label: dltUsers },
            position: { x: padding, y: lastYIndex },
            width: basicNodeWidth + padding * 2,
            height: padding * 2,
            style: {
              textAlign: 'left',
            },
            parentId: domainNodeName,
            extent: 'parent',
          };
          nodes.push(dltNode);
          lastYIndex += dltNode.height! + padding;

          const systems = await catalogApi.getEntities({
            fields: ['metadata.name', 'metadata.title'],
            filter: {
              kind: 'System',
              'spec.domain': domain.metadata.name,
            },
            order: { field: 'metadata.title', order: 'asc' },
          });

          const systemsNodeName = `systems-${domain.metadata.name}`;
          const systemsNode: BuiltInNode = {
            id: systemsNodeName,
            data: { label: 'Systems' },
            position: { x: padding, y: lastYIndex },
            width: basicNodeWidth + padding * 2,
            height: padding + systems.items.length * padding * 1.5,
            parentId: domainNodeName,
            extent: 'parent',
          };
          nodes.push(systemsNode);
          lastYIndex += systemsNode.height!;

          if (systems && systems.items) {
            for (let i = 0; i < systems.items.length; i++) {
              const system = systems.items[i];
              const systemNode: BuiltInNode = {
                id: system.metadata.name,
                data: { label: system.metadata.title || system.metadata.name },
                position: { x: padding, y: padding + i * padding * 1.5 },
                style: {
                  backgroundColor: '#169d44',
                  color: '#FFFFFF',
                },
                parentId: systemsNodeName,
                extent: 'parent',
              };
              nodes.push(systemNode);
            }
          }

          domainNode.width = systemsNode.width! + padding * 2;
          domainNode.height! = lastYIndex + padding;
          domainNode.position.x = i * (domainNode.width + padding);
          domainNode.position.y = 0;
        }
      }

      setNodes(nodes);
    };

    getData();
  }, [setNodes]);

  return (
    <ReactFlow
      nodes={nodes}
      onNodesChange={onNodesChange}
      // nodesConnectable={false}
      nodesDraggable={false}
      // nodesFocusable={false}
      // elementsSelectable={false}
      fitView
    >
      <MiniMap />
      <Controls showInteractive={false} />
    </ReactFlow>
  );
}
