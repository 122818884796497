import {
  createComponentExtension,
  createPlugin,
} from '@backstage/core-plugin-api';
import { CustomAboutCardProps } from './components/CustomAboutCard';
import {
  createFromTemplateRouteRef,
  viewTechDocRouteRef,
} from './components/CustomAboutCard/routes';
import { EntityMetadataCardProps } from './components/EntityMetadataCard';

export const vorwerkPlugin = createPlugin({
  id: 'vorwerk',
  externalRoutes: {
    viewTechDoc: viewTechDocRouteRef,
    createFromTemplate: createFromTemplateRouteRef,
  },
});

export const EntityMetadataCard: (
  props: EntityMetadataCardProps,
) => React.JSX.Element = vorwerkPlugin.provide(
  createComponentExtension({
    name: 'EntityMetadataCard',
    component: {
      lazy: () =>
        import('./components/EntityMetadataCard').then(
          m => m.EntityMetadataCard,
        ),
    },
  }),
);

export const CustomEntityAboutCard: (
  props: CustomAboutCardProps,
) => JSX.Element = vorwerkPlugin.provide(
  createComponentExtension({
    name: 'CustomEntityAboutCard',
    component: {
      lazy: () =>
        import('./components/CustomAboutCard').then(m => m.CustomAboutCard),
    },
  }),
);

export { CustomAsyncApiDefinitionWidget } from './components/CustomAsyncApiDefinitionWidget';

export * from './components/CulinaryExplorerContent';
export * from './components/EntityCriticalityPicker';
export * from './components/EntityPersonalDataPicker';
