import {
  Content,
  ContentHeader,
  Header,
  Page,
  SupportButton,
} from '@backstage/core-components';
import {
  FeaturedDocsCard,
  HomePageStarredEntities,
  HomePageToolkit,
  WelcomeTitle,
} from '@backstage/plugin-home';
import { Grid } from '@material-ui/core';
import { AnnouncementsCard } from '@procore-oss/backstage-plugin-announcements';
import React from 'react';
import {
  ArgoCdIcon,
  ConfluenceIcon,
  GitLabIcon,
  GrafanaIcon,
  JenkinsIcon,
  JiraIcon,
  MiroIcon,
  SlackIcon,
  SnykIcon,
} from '../../assets/icons/customIcons';

export const HomePage = (
  <Page themeId="home">
    <Header title={<WelcomeTitle />} pageTitleOverride="Home" />
    <Content>
      <ContentHeader title="">
        <SupportButton>Your start page</SupportButton>
      </ContentHeader>
      <Grid container justifyContent="center" spacing={6}>
        <Grid container item xs={12}>
          <Grid item xs={12} md={6}>
            <AnnouncementsCard max={3} />
          </Grid>
          <Grid item xs={12} md={6}>
            <FeaturedDocsCard
              filter={{
                'spec.type': 'documentation',
                'metadata.tags': 'featured',
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <HomePageToolkit
              tools={[
                {
                  url: 'https://gitlab.tmecosys.net/',
                  label: 'GitLab',
                  icon: <GitLabIcon />,
                },
                {
                  url: 'https://jenkins.tes-build-prod.cookidoo.vorwerk-digital.com/',
                  label: 'Embedded Jenkins',
                  icon: <JenkinsIcon />,
                },
                {
                  url: 'https://eu.argocd.prod.cookidoo.vorwerk-digital.com/',
                  label: 'ArgoCD',
                  icon: <ArgoCdIcon />,
                },
                {
                  url: 'https://cookidoo.grafana.net/',
                  label: 'Cookidoo Grafana',
                  icon: <GrafanaIcon />,
                },
                {
                  url: 'https://app.snyk.io/',
                  label: 'Snyk',
                  icon: <SnykIcon />,
                },
                {
                  url: 'https://miro.com/app/dashboard/',
                  label: 'Miro',
                  icon: <MiroIcon />,
                },
                {
                  url: 'https://vorwerkproducts.atlassian.net/jira',
                  label: 'Jira',
                  icon: <JiraIcon />,
                },
                {
                  url: 'https://vorwerkproducts.atlassian.net/wiki',
                  label: 'Confluence',
                  icon: <ConfluenceIcon />,
                },
                {
                  url: 'https://vorwerkdigital.enterprise.slack.com/',
                  label: 'Slack',
                  icon: <SlackIcon />,
                },
              ]}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <HomePageStarredEntities groupByKind />
          </Grid>
        </Grid>
      </Grid>
    </Content>
  </Page>
);
