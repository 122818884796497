import {
  RadarEntry,
  TechRadarApi,
  TechRadarLoaderResponse,
} from '@backstage-community/plugin-tech-radar';
import { DiscoveryApi, FetchApi } from '@backstage/core-plugin-api';

type Options = {
  discoveryApi: DiscoveryApi;
  fetchApi: FetchApi;
};
export class TechRadarClient implements TechRadarApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly fetchApi: FetchApi;

  constructor(options: Options) {
    this.discoveryApi = options.discoveryApi;
    this.fetchApi = options.fetchApi;
  }

  async load(): Promise<TechRadarLoaderResponse> {
    const data = await this.fetchApi
      .fetch(`${await this.discoveryApi.getBaseUrl('proxy')}/tech-radar-data`)
      .then(response => response.json());
    return {
      ...data,
      entries: data.entries.map((entry: RadarEntry) => ({
        ...entry,
        timeline: entry.timeline.map(timeline => ({
          ...timeline,
          date: new Date(timeline.date),
        })),
      })),
    };
  }
}
