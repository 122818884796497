import { EntityAutocompletePicker } from '@backstage/plugin-catalog-react';
import React from 'react';
import { PERSONAL_DATA_LABEL } from '../../constants';
import { CustomFilters, EntityPersonalDataFilter } from '../../filters';

export const EntityPersonalDataPicker = () => {
  return (
    <EntityAutocompletePicker<CustomFilters>
      label="Personal data"
      name="personalData"
      path={`metadata.labels.${PERSONAL_DATA_LABEL}`}
      Filter={EntityPersonalDataFilter}
    />
  );
};
