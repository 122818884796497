import { EntityAutocompletePicker } from '@backstage/plugin-catalog-react';
import React from 'react';
import { CustomFilters, EntityCriticalityFilter } from '../../filters';
import { CRITICALITY_LABEL } from '../../constants';
import { catalogTranslationRef } from '../../translation';
import { useTranslationRef } from '@backstage/core-plugin-api/alpha';

export const EntityCriticalityPicker = () => {
  const { t } = useTranslationRef(catalogTranslationRef);

  return (
    <EntityAutocompletePicker<CustomFilters>
      label={t('entityCriticalityPicker.title')}
      name="criticalities"
      path={`metadata.labels.${CRITICALITY_LABEL}`}
      Filter={EntityCriticalityFilter}
    />
  );
};
