import {
  Content,
  ContentHeader,
  SupportButton,
} from '@backstage/core-components';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import CulinaryContent from './CulinaryContent';

const useStyles = makeStyles(
  {
    root: {
      height: '100%',
      maxHeight: '100%',
      minHeight: 0,
    },
  },
  { name: 'ExploreCulinaryContent' },
);

export const CulinaryExplorerContent = (props: { title?: string }) => {
  const classes = useStyles();

  return (
    <Content noPadding stretch className={classes.root}>
      <ContentHeader title={props.title ?? ''}>
        <SupportButton>Explore the Culinary Product Line</SupportButton>
      </ContentHeader>
      <CulinaryContent />
    </Content>
  );
};
